import React from "react";
import { Link, useHistory } from "react-router-dom";
import siteLogo from "../../assets/images/logo_white.png";
import Menu_Png from "../../assets/images/menu.png";
import { Dropdown } from "react-bootstrap";

const Header = (props) => {
  let history = useHistory();
  const openMenue = () => {
    document.getElementsByTagName("body")[0].classList.add("opne-menu");
  };
  const handleLogout = () => {
    localStorage.clear();
    props.UpdateLocalStorage();
    history.push("/");
  };
  return (
    <div>
      <header className="main-header">
        <div className="header-container mx-auto">
          <nav className="navbar navbar-expand-lg py-3">
            <Link className="navbar-brand m-0 p-0" to="/">
              <img
                src={siteLogo}
                width={150}
                alt="site-logo"
                className="d-md-block"
              />
            </Link>
            <button className="navbar-toggler" onClick={() => openMenue()}>
              <img src={Menu_Png} width={24} className="open-menu" alt="" />
            </button>
            <ul className="navbar-nav ml-auto">
              <li key="nav">
                <div>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      className="drbtn btn  btn-outline-primary"
                      id="dropdown-basic"
                    >
                      Admin
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleLogout()}>
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </div>
  );
};

export default Header;
