import React from "react";
import { ApiServices } from "../../../services/ApiServices";

const Reports = () => {

  const UserReport = () => {
    ApiServices.UserReport()
      .then((response) => {
        const Url = response.data.data;
        window.open(Url);
      })
      .catch((e) => {
        console.log(e, "Failed to get repo");
      });
  };

  const GetBookingReport = () => {
    ApiServices.BookingReport()
      .then((response) => {
        const Url = response.data.data;
        window.open(Url);
      })
      .catch((e) => {
        console.log(e, "Failed to get repo");
      });
  };

  const PaymentReport = () => {
    ApiServices.PaymentReport()
      .then((response) => {

        const Url = response.data.data;
        window.open(Url);
      })
      .catch((e) => {
        console.log(e, "Failed to get repo");
      });
  };

  const DiscountReports = () => {
    ApiServices.PromoCodeReport()
      .then((response) => {

        const Url = response.data.data;
        window.open(Url);
      })
      .catch((e) => {
        console.log(e, "Failed to get repo");
      });
  };

  const ratingReports = () => {
    ApiServices.RatingReports()
      .then((response) => {

        const Url = response.data.data;
        window.open(Url);
      })
      .catch((e) => {
        console.log(e, "Failed to get repo");
      });
  };
  return (
    <div>
      <main className="main-contant">
        <div className="clearfix">
          {/* {BookingReport} */}
          <h1 className="page-title float-left">Reports</h1>
        </div>
        <div className="page-content">
          <div className="card-wrap">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-12">
                  {" "}
                  <button
                    type="button"
                    className="drbtn report-btn btn btn-outline-primary"
                    onClick={() => UserReport()}
                  >
                    Users
                  </button>
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                  {" "}
                  <button
                    type="button"
                    className="drbtn report-btn btn btn-outline-primary"
                    onClick={() => GetBookingReport()}
                  >
                    Bookings
                  </button>
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                  <button
                    type="button"
                    className="drbtn report-btn btn btn-outline-primary"
                    onClick={() => PaymentReport()}
                  >
                    Payments
                  </button>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-4 col-md-4 col-12">
                  <button
                    type="button"
                    className="drbtn report-btn btn btn-outline-primary"
                    onClick={() => DiscountReports()}
                  >
                    Discount Codes
                  </button>
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                  <button
                    type="button"
                    className="drbtn report-btn btn btn-outline-primary"
                    onClick={() => ratingReports()}
                  >
                    Review Rating Report
                  </button>
                </div>
              </div>
            </div>

            {/* <div className="col-md-4">
              <div className="form-group">
                <label htmlFor>Start Date</label>
                <input
                  disabled={BookingReport ? true : false}
                  type="date"
                  onChange={(e) => {
                    setStartDate(new Date(e.target.value).getTime());
                  }}
                />12
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor>End Date</label>
                <input
                  disabled={BookingReport ? true : false}
                  type="date"
                  onChange={(e) => {
                    setEndDate(new Date(e.target.value).getTime());
                  }}
                />
              </div>
            </div> */}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Reports;
