import React, { useEffect, useState } from "react";
import { ApiServices } from "../../../services/ApiServices";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import moment from 'moment';
import BlockUi from "react-block-ui";

const Users = () => {
  const [UserList, setUserList] = useState([]);
  const [Blocking, setBlocking] = useState(false);
  const [Page, setPage] = useState(1);
  const [PageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [firstText, setFirstText] = useState(0);
  const [secondText, setSecondText] = useState(0);
  const [SearchParam, setSearchParam] = useState("");

  useEffect(() => {
    getUsersList();
  }, [PageSize, Page]);

  useEffect(() => {
    if (UserList.length === 0) {
      setSecondText(0);
      setFirstText(0);
    } else {
      setFirstText(Page * PageSize - PageSize + 1);
      if (UserList.length !== PageSize) {
        setSecondText(
          Math.abs(Page * PageSize - PageSize + UserList.length)
        );
      } else {
        setSecondText(Page * PageSize);
      }
    }
  }, [UserList]);

  const getUsersList = () => {
    let params = {
      page: Page,
      limit: PageSize,
    };
    if (SearchParam !== "" || SearchParam !== undefined) {
      params.search = SearchParam;
    }
    setBlocking(true);
    ApiServices.getUsers(params)
      .then((response) => {
        setUserList(response.data.data);
        setTotalCount(response.data.count);
        setBlocking(false);
      })
      .catch((e) => {
        setBlocking(false);
        console.log(e, "Failed to get conutries");
      });
  };
  const onLimitChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };
  const previousPage = () => {
    if (Page > 1) {
      setPage(Page - 1);
    }
  };
  const nextPage = () => {
    if (Page < Math.ceil(totalCount / PageSize)) {
      setPage(Page + 1);
    }
  };

  const ConformAction = (Action, id) => {
    confirmAlert({
      title:
        Action === false
          ? "Are you Sure to Disable it..?"
          : "Are you Sure to Enable it..?",
      buttons: [
        {
          label: "No",
        },
        {
          label: "Yes",
          onClick: () => {
            UserAction(Action, id);
          },
        },
      ],
    });
  };

  const UserAction = (Action, id) => {
    let params = {
      userId: id,
      isActive: Action,
    };
    ApiServices.UserAction(params)
      .then((response) => {
        let res = response.data;
        if (res.status === 200) {
          toast.success(res.msg);
          getUsersList();
        } else {
          toast.error(res.msg);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleSarch = () => {
    setPage(1);
    getUsersList();
  };
  return (
    <div>
      <BlockUi blocking={Blocking}>
        <main className="main-contant">
          <div className="clearfix">
            <h1 className="page-title float-left">Users</h1>
          </div>
          <div className="page-content">
            <div className="filter-wrap mt-4">
              <ul className="list-inline clearfix">
                <li className="list-inline-item" key="item">
                  <ul className="llist-inline pl-0 clearfix m-0 secrch-wrap">
                    <li className="list-inline-item mr-3 secrch-input" key="search">
                      <input
                        type="text"
                        onChange={(e) => setSearchParam(e.target.value)}
                        className="form-control"
                        placeholder="Search here..."
                      />
                    </li>
                    <li className="list-inline-item mr-0 secrch-btn" key="submit">
                      <button type="button" onClick={handleSarch} className="drbtn btn  btn-outline-primary">
                        Search
                    </button>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="card-wrap">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr key="head">
                      {/* <th>User Id</th> */}
                      <th>User Name</th>
                      <th>User Type</th>
                      <th>Email Address</th>
                      <th>Phone Number</th>
                      <th>Business Connected To (if barber)</th>
                      <th>Last Service with Barber Name</th>
                      <th>Last Used QSkipper</th>
                      <th>Reactive on</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {UserList !== undefined ? (
                      UserList.map((user, index) => {
                        return (
                          <tr key={index.toString()}>
                            {/* <td> {user.id} </td> */}
                            <td> {user.name} </td>
                            <td> {user.userAccountType} </td>
                            <td> {user.email} </td>
                            <td> {user.phoneNumber}</td>
                            <td> {user.businessConnectedTo}</td>
                            <td> {user.lastHaircutWithBarberName} </td>
                            <td> {user.createdOn != null ? moment(Number(user.createdOn)).format("DD/MM/YYYY") : "-"}</td>
                            <td> {user.isActive && user.reactivateOn != null ? moment(Number(user.reactivateOn)).format("DD/MM/YYYY") : "-"} </td>
                            <td>
                              {" "}
                              {user.isActive ? (
                                <div style={{ color: "green" }}>Enable</div>
                              ) : (
                                  <div style={{ color: "red" }}>Disable</div>
                                )}{" "}
                            </td>
                            <td>
                              {user.isActive ? (
                                <button
                                  type="button"
                                  className="btn btn-outline-danger"
                                  onClick={() => ConformAction(false, user.id)}
                                >
                                  Disable
                                </button>
                              ) : (
                                  <button
                                    type="button"
                                    className="btn btn-outline-success"
                                    onClick={() => ConformAction(true, user.id)}
                                  >
                                    Enable
                                  </button>
                                )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                        <div className="text-center">
                          <p>No User Found</p>
                        </div>
                      )}
                  </tbody>
                </table>
              </div>
              <div className="paginations">
                <p>Items per page:</p>
                <select onChange={onLimitChange} name="limit">
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                  <option value={50}>50</option>
                </select>
                <p>
                  <span>{firstText}</span>-<span>{secondText}</span> of{" "}
                  <span>{totalCount}</span>
                  <i className="fa fa-angle-left" onClick={previousPage} />
                  <i className="fa fa-angle-right" onClick={nextPage} />
                </p>
              </div>
            </div>
          </div>
        </main>
      </BlockUi>
    </div>
  );
};

export default Users;
