import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import Login from "./components/pages/auth/Login";
import ForgotPassword from "./components/pages/auth/ForgotPassword";
import Countries from "./components/pages/Menus/Countries";
import PageNotFound from "./components/sharedComponent/PageNotFound";
import Header from "./components/sharedComponent/Header";
import Navigation from "./components/sharedComponent/Navigation";
import DiscountCodes from "./components/pages/Menus/DiscountCodes";
import Users from "./components/pages/Menus/Users";
import Reports from "./components/pages/Menus/Reports";
import AddCountries from "./components/pages/Menus/AddCountries";
import AddDiscountCode from "./components/pages/Menus/AddDiscountCode";
import axios from "axios";
import Earnings from "./components/pages/Menus/Earnings";

const Routes = () => {
  const [isVerified, setVerified] = useState(false);
  useEffect(() => {
    axios.interceptors.response.use(
      (res) => {
        // Add configurations here
        return res;
      },
      (err) => {
        if (err.response.status == 401) {
          localStorage.clear()
          UpdateLocalStorage()
        }
        return Promise.reject(err);
      }
    );
  }, []);

  useEffect(() => {
    let Token = localStorage.getItem("accessToken");
    if (Token !== undefined && Token !== "" && Token !== null) {
      setVerified(true);
    } else {
      setVerified(false);
    }
  }, []);

  const UpdateLocalStorage = () => {
    let Token = localStorage.getItem("accessToken");
    if (Token !== undefined && Token !== "" && Token !== null) {
      setVerified(true);
    } else {
      setVerified(false);
    }
  };

  const ProtectedRoute = ({ component: Component, isVerified, ...rest }) => {
    return isVerified ? (
      <Route
        {...rest}
        render={(props) => {
          return <Component {...props} />;
        }}
      />
    ) : (
      <Login UpdateLocalStorage={UpdateLocalStorage} />
    );
  };

  const PublicRoute = ({ component: Component, isVerified, ...rest }) => {
    return !isVerified ? (
      <Route
        {...rest}
        render={(props) => {
          return <Component {...props} />;
        }}
      />
    ) : (
      <Redirect to="/" />
    );
  };

  return (
    <div>
      {/* HEADER COMPONENT */}
      {isVerified ? <Header UpdateLocalStorage={UpdateLocalStorage} /> : ""}
      {isVerified ? <Navigation /> : ""}

      <Switch>
        {/* PUBLIC ROUTES */}

        <Route
          exact
          path="/"
          component={(props) =>
            isVerified ? (
              <Redirect to="/countries" />
            ) : (
              <Login UpdateLocalStorage={UpdateLocalStorage} />
            )
          }
        />
        <PublicRoute
          exact
          path="/forgot-password"
          component={ForgotPassword}
          isVerified={isVerified}
        />
        {/* PROTECTED ROUTES */}

        {/* DEMO ROUTE */}
        {/* <Route
          path="/add-discount-codes"
          component={(props) => {
            if (isVerified) {
              return <AddDiscountCode propsValue={props} />;
            } else {
              return (
                <Login UpdateLocalStorage={UpdateLocalStorage} />
              );
            }
          }}
        /> */}
        {/* DEMO ROUTE */}
        <ProtectedRoute
          exact
          path="/countries"
          component={Countries}
          isVerified={isVerified}
        />
        <ProtectedRoute
          exact
          path="/add-countries"
          component={AddCountries}
          isVerified={isVerified}
        />
        <ProtectedRoute
          exact
          path="/edit-countries"
          component={AddCountries}
          isVerified={isVerified}
        />
        <ProtectedRoute
          exact
          path="/add-discount-codes"
          component={AddDiscountCode}
          isVerified={isVerified}
        />
        <ProtectedRoute
          exact
          path="/discount-codes"
          component={DiscountCodes}
          isVerified={isVerified}
        />
        <ProtectedRoute
          exact
          path="/users"
          component={Users}
          isVerified={isVerified}
        />
        <ProtectedRoute
          exact
          path="/reports"
          component={Reports}
          isVerified={isVerified}
        />
        <ProtectedRoute
          exact
          path="/earnings"
          component={Earnings}
          isVerified={isVerified}
        />
        {/*<Route component={PageNotFound} />*/}
        <Route
          exact
          path="*"
          component={(props) =>
            <Redirect to="/countries" />
          }
        />
      </Switch>
    </div>
  );
};

export default Routes;

// {/* ALL PATIENTS ROUTES */}
// <Route path="/all-patients" component={(props) => {
//   if (userData.isLogin) {
//     return (<Allpatients propsValue={props} />)
//   }

//   else {
//     return (<Signin propValue={props} updateParentDocState={this.updateParentDocState} />)
//   }
// }} />
