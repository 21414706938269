import axios from "axios";

export const ApiServices = {
    login(data) {
        return axios.post("login", data);
    },
    forgotPassword(data) {
        return axios.post("forgotPassword", data);
    },
    getAPIHeader() {
        let access_token = localStorage.getItem("accessToken");
        return {
            Authorization: `Bearer ${access_token}`,
        }
    },
    getCountries(params) {
        return axios.get("getCountries", {
            headers: this.getAPIHeader(), params
        })
    },
    getMyEarningsCount(data) {
        return axios.post("adminMyEarningTotalCounts", data, {
            headers: this.getAPIHeader(),
        });
    },
    getMyEarningsList(data) {
        return axios.post("adminMyEarningBookingsListing", data, {
            headers: this.getAPIHeader(),
        });
    },
    getCountryDetails(countryID) {
        return axios.get("getOneCountry/" + countryID, {
            headers: this.getAPIHeader(),
        });
    },
    getDiscountCodes(params) {
        return axios.get("getPromoCodes", {
            headers: this.getAPIHeader(), params
        })
    },
    getUsers(params) {
        return axios.get("getUsers", {
            headers: this.getAPIHeader(), params
        })
    },
    getCountryDropList() {
        return axios.get("getCountriesDropdowns", {
            headers: this.getAPIHeader(),
        })
    },
    AddCountries(data) {
        return axios.post("AddCountry", data, {
            headers: this.getAPIHeader(),
        });
    },
    EditCountries(data) {
        return axios.put("updateCountry", data, {
            headers: this.getAPIHeader(),
        });
    },
    AddPrompCode(data) {
        return axios.post("addPromoCode", data, {
            headers: this.getAPIHeader(),
        });
    },
    getBarbourName() {
        return axios.get("getProviderList", {
            headers: this.getAPIHeader(),
        })
    },
    AddDiscountCode(data) {
        return axios.post("addPromoCode", data, {
            headers: this.getAPIHeader(),
        });
    },
    CountryAction(data) {
        return axios.put("changeCountryStatus", data, {
            headers: this.getAPIHeader(),
        });
    },
    UserAction(data) {
        return axios.put("changeUserStatus", data, {
            headers: this.getAPIHeader(),
        });
    },
    getPromoCodeByID(Id) {
        return axios.get("getPromoCodeById/" + Id, {
            headers: this.getAPIHeader(),
        })
    },
    PromoUpdate(data) {
        return axios.put("updatePromoCode", data, {
            headers: this.getAPIHeader(),
        });
    },
    UserReport() {
        return axios.get("getUserReports", {
            headers: this.getAPIHeader(),
        })
    },
    PromoCodeReport() {
        return axios.get("getPromoCodeReports", {
            headers: this.getAPIHeader(),
        })
    },
    BookingReport(data) {
        return axios.post("getBookingsReports", data, {
            headers: this.getAPIHeader(),
        });
    },
    PaymentReport() {
        return axios.get("getPaymentsHistoryReports", {
            headers: this.getAPIHeader(),
        })
    },
    RatingReports() {
        return axios.post("/getReviewRatingReports", {}, {
            headers: this.getAPIHeader(),
        });
    }
}


