import React, { useState } from "react";
import { ApiServices } from "../../../services/ApiServices";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
/* modules for validation */
import { isEmail } from "validator";
import Logo from "../../../assets/images/logo.png";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();


    const values = { email: email, password: password, timeZone: timeZone };
    if (values.email.trim() === "" && values.password.trim() === "" && !isEmail(values.email)) {
      toast.error("Enter Valid Credentials");
    } else {
      // LOGIN API SERVICE CALL
      ApiServices.login(values)
        .then((response) => {
          let res = response.data;
          if (res.status === 200) {
            toast.success(res.msg);
            localStorage.setItem("accessToken", res.token);
            props.UpdateLocalStorage();
          } else {
            toast.error(res.msg);
          }
        })
        .catch((e) => {
          toast.error(e.response.data.msg);
        });
    }
  };

  return (
    <div className="container">
      <div className="user-login-wrap row p-3">
        <div className="card-wrap col-md-5 mx-auto add-question-wrap">
          <a className="navbar-brand text-center d-block mb-5 p-0">
            <img src={Logo} width={150} alt="site-logo" />
          </a>
          <form name="loginForm" onSubmit={handleSubmit}>
            <div className="form-group mb-4">
              <label >Email</label>
              <input
                type="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="form-group mb-4">
              <label >Password</label>
              <input
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="drbtn btn btn-outline-primary"
                style={{ width: "100%" }}
              >
                Sign In
              </button>
            </div>
          </form>
          <Link to="/forgot-password" className="forgotpass">
            Forgot your password?
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
