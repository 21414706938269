import React, { useEffect, useState } from "react";
import { ApiServices } from "../../../services/ApiServices";
import { Link, useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import BlockUi from "react-block-ui";
import { toast } from "react-toastify";

const Countries = (props) => {
  const history = useHistory()
  const [Countries, setCountries] = useState([]);
  const [Page, setPage] = useState(1);
  const [Blocking, setBlocking] = useState(false);
  const [PageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [firstText, setFirstText] = useState(0);
  const [secondText, setSecondText] = useState(0);
  const [SearchParam, setSearchParam] = useState("");

  useEffect(() => {
    getCountries();
  }, [PageSize, Page]);

  useEffect(() => {
    if (Countries && Countries.length === 0) {
      setSecondText(0);
      setFirstText(0);
    } else {
      setFirstText(Page * PageSize - PageSize + 1);
      if (Countries && Countries.length !== PageSize) {
        setSecondText(Math.abs(Page * PageSize - PageSize + Countries.length));
      } else {
        setSecondText(Page * PageSize);
      }
    }
  }, [Countries]);

  const getCountries = () => {
    let params = {
      page: Page,
      limit: PageSize,
    };
    if (SearchParam !== "" || SearchParam !== undefined) {
      params.search = SearchParam;
    }
    setBlocking(true);
    ApiServices.getCountries(params)
      .then((response) => {
        setCountries(response.data.data);
        setTotalCount(response.data.count);
        setBlocking(false);
      })
      .catch((e) => {
        console.log(e, "Failed to get conutries");
        toast.error(e.response.data.msg);
        setBlocking(false);
        if (e.response.status == 401) {
          localStorage.clear();
          history.push("/");
        }
      })
  };
  const onLimitChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };
  const previousPage = () => {
    if (Page > 1) {
      setPage(Page - 1);
    }
  };
  const nextPage = () => {
    if (Page < Math.ceil(totalCount / PageSize)) {
      setPage(Page + 1);
    }
  };

  const ConformAction = (Action, id) => {
    confirmAlert({
      title:
        Action === 0
          ? "Are you Sure to Disable it..?"
          : "Are you Sure to Enable it..?",
      buttons: [
        {
          label: "No",
        },
        {
          label: "Yes",
          onClick: () => {
            CountryAction(Action, id);
          },
        }
      ],
    });
  };
  const CountryAction = (Action, id) => {
    let params = {
      countryId: id,
      isActive: Action,
    };
    ApiServices.CountryAction(params)
      .then((response) => {
        let res = response.data;

        if (res.status === 200) {
          toast.success(res.msg);
          getCountries();
        } else {
          toast.error(res.msg);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSarch = () => {
    setPage(1);
    getCountries();
  };
  return (
    <BlockUi blocking={Blocking}>
      <div>
        <main className="main-contant">
          <div className="clearfix">
            <h1 className="page-title float-left">Countries</h1>
          </div>
          <div className="page-content">
            <div className="filter-wrap mt-4">
              <ul className="list-inline clearfix">
                <li className="list-inline-item" key="item">
                  <ul className="llist-inline pl-0 clearfix m-0 secrch-wrap">
                    <li
                      className="list-inline-item mr-3 secrch-input"
                      key="serch"
                    >
                      <input
                        type="text"
                        onChange={(e) => setSearchParam(e.target.value)}
                        className="form-control"
                        placeholder="Search here..."
                      />
                    </li>
                    <li
                      className="list-inline-item mr-0 secrch-btn"
                      key="submit"
                    >
                      <button
                        className="drbtn btn  btn-outline-primary"
                        onClick={handleSarch}
                      >
                        Search
                      </button>
                    </li>
                  </ul>
                </li>
                <li className="list-inline-item float-right" key="add_country">
                  <Link to="/add-countries">
                    <button className="drbtn btn  btn-outline-primary">
                      Add Country
                    </button>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="card-wrap">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr key="head">
                      <th>Country Code</th>
                      <th>Country</th>
                      <th>Currency for top up</th>
                      <th>Local Currency for service Price</th>
                      <th>Price per credit</th>
                      <th>Trial credit offered</th>
                      <th>Currency Sign</th>
                      <th>Medium credit Purchase</th>
                      {/* <th>Booking Fee</th>
                      <th>Transaction Fee</th>
                      <th>Transaction Fee (%)</th>
                      <th>Sort Code</th>
                      <th>Currency Conversion from GBP</th> */}
                      <th>Sort Code</th>
                      <th>Account Number</th>
                      <th>status</th>
                      <th>Actions</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Countries !== undefined ? (
                      Countries.map((countrie, index) => {
                        return (
                          <tr key={index.toString()}>
                            <td key="1"> {countrie.mobCode != null ? countrie.mobCode : "-"} </td>
                            <td key="2"> {countrie.country} </td>
                            <td key="3"> {countrie.currencyTopup} </td>
                            <td key="4"> {countrie.currencyLocal} </td>
                            <td key="5"> {countrie.pricePerCredit} </td>
                            <td key="6"> {countrie.creditsOffered}</td>
                            <td key="7"> {countrie.currencySign}</td>
                            <td key="8"> {countrie.minimumCredit} </td>
                            <td key="9"> {countrie.sortCodeLable} </td>
                            <td key="10"> {countrie.accountNumberLable} </td>
                            <td key="11">
                              {" "}
                              {countrie.status === "1" ? (
                                <div style={{ color: "green" }}>Enable</div>
                              ) : (
                                <div style={{ color: "red" }}>Disable</div>
                              )}{" "}
                            </td>
                            <td>
                              {" "}
                              {countrie.status === "1" ? (
                                <button
                                  type="button"
                                  className="btn btn-outline-danger"
                                  onClick={() =>
                                    ConformAction(0, countrie.countryId)
                                  }
                                >
                                  Disable
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-outline-success"
                                  onClick={() =>
                                    ConformAction(1, countrie.countryId)
                                  }
                                >
                                  Enable
                                </button>
                              )}{" "}
                            </td>
                            <td>
                              <Link to={"/edit-countries?"+countrie?.countryId}>
                                <button
                                  type="button"
                                  className="btn btn-outline-success"
                                // onClick={() =>
                                //   ConformAction(1, countrie.countryId)
                                // }
                                >
                                  Edit
                                </button>
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <div className="text-center">
                        <p>No Country Found</p>
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="paginations">
                <p>Items per page:</p>
                <select onChange={(e) => onLimitChange(e)} name="limit">
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                  <option value={50}>50</option>
                </select>
                <p>
                  <span>{firstText}</span>-<span>{secondText}</span> of{" "}
                  <span>{totalCount}</span>
                  <i className="fa fa-angle-left" onClick={previousPage} />
                  <i className="fa fa-angle-right" onClick={nextPage} />
                </p>
              </div>
            </div>
          </div>
        </main>
      </div>
    </BlockUi>
  );
};

export default Countries;
