import React from "react";
import { Link, useLocation } from "react-router-dom";
import Close_png from "../../assets/images/close.png";
import MiniLogo_png from "../../assets/images/mini-logo.png";

const Navigation = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const closeMenue = () => {
    document.getElementsByTagName("body")[0].classList.remove("opne-menu");
  };

  return (
    <div>
      <aside className="main-nav">
        <div className="d-block d-lg-none py-3 px-3 mini-logo-bar">
          <Link className="navbar-brand m-0 p-0" to="/">
            <img
              src={MiniLogo_png}
              className="d-block d-lg-none"
              width={50}
              alt="site-logo"
            />
          </Link>
          <button className="navbar-toggler float-right">
            <img
              src={Close_png}
              width={24}
              className="open-menu"
              alt="close icon"
              onClick={() => closeMenue()}
            />
          </button>
        </div>
        <div className="sidebar">
          <ul className="menu-list list-unstyled" id="menu">
            <li className="nav-btn" key="navbar" onClick={() => closeMenue()}>
              <Link
                to="/countries"
                className={
                  splitLocation[1] === "countries"
                    ? "list-item collapsed active"
                    : "list-item collapsed"
                }
              >
                Countries
              </Link>
            </li>
            <li className="nav-btn" key="closemenu" onClick={() => closeMenue()}>
              <Link
                to="/discount-codes"
                className={
                  splitLocation[1] === "discount-codes" ||
                  splitLocation[1] === "add-discount-codes"
                    ? "list-item collapsed active"
                    : "list-item collapsed"
                }
              >
                Discount Codes
              </Link>
            </li>
            <li className="nav-btn" key="close_menu" onClick={() => closeMenue()}>
              <Link
                to="/users"
                className={
                  splitLocation[1] === "users"
                    ? "list-item collapsed active"
                    : "list-item collapsed"
                }
              >
                Users
              </Link>
            </li>
            <li className="nav-btn" key="close_menu" onClick={() => closeMenue()}>
              <Link
                to="/earnings"
                className={
                  splitLocation[1] === "earnings"
                    ? "list-item collapsed active"
                    : "list-item collapsed"
                }
              >
                Earnings
              </Link>
            </li>
            <li className="nav-btn" key="CloseMenu" onClick={() => closeMenue()}>
              <Link
                to="/reports"
                className={
                  splitLocation[1] === "reports"
                    ? "list-item collapsed active"
                    : "list-item collapsed"
                }
              >
                Reports
              </Link>
            </li>
          </ul>
        </div>
      </aside>
    </div>
  );
};

export default Navigation;
