import React, { useEffect, useState } from "react";
import { ApiServices } from "../../../services/ApiServices";
import { Link, useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import BlockUi from "react-block-ui";
import { toast } from "react-toastify";
import LeftArrow from "../../../assets/images/left-arrow.png";
import RightArrow from "../../../assets/images/right-arrow.png";
import moment from 'moment';

const dateFormat = 'YYYY/MM/DD'

const Earnings = (props) => {
    const history = useHistory()
    const [earningsList, setEarningsList] = useState([]);
    const [Page, setPage] = useState(1);
    const [Blocking, setBlocking] = useState(false);
    const [PageSize, setPageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [firstText, setFirstText] = useState(0);
    const [secondText, setSecondText] = useState(0);
    const [currentWeekStartDate, setCurrentWeekStartDate] = useState("");
    const [currentWeekEndDate, setCurrentWeekEndDate] = useState("");
    const [advancePaymentPending, setAdvancePaymentPending] = useState("");
    const [advancePaymentTotal, setAdvancePaymentTotal] = useState("");

    useEffect(() => {
        if (currentWeekStartDate !== "") {
            getMyEarningsCount()
            getMyEarningsList()
        }
    }, [currentWeekStartDate]);

    useEffect(() => {
        if (earningsList && earningsList.length === 0) {
            setSecondText(0);
            setFirstText(0);
        } else {
            setFirstText(Page * PageSize - PageSize + 1);
            if (earningsList && earningsList.length !== PageSize) {
                setSecondText(Math.abs(Page * PageSize - PageSize + earningsList.length));
            } else {
                setSecondText(Page * PageSize);
            }
        }
    }, [earningsList]);

    useEffect(() => {
        setCurrentWeek()
    }, [])

    const setCurrentWeek = () => {
        var janFirst = moment('2022-01-01')
        var differenceInDays = moment().diff(janFirst, 'days')
        var weekDay = differenceInDays % 14
        setCurrentWeekStartDate(moment(new Date()).subtract(weekDay, 'days').format(dateFormat))
        setCurrentWeekEndDate(moment(new Date()).subtract(weekDay, 'days').add(13, 'days').format(dateFormat))
    }

    const onTapRightWeek = () => {
        setCurrentWeekStartDate(moment(currentWeekEndDate, dateFormat).add(1, 'days').format(dateFormat))
        setCurrentWeekEndDate(moment(currentWeekEndDate, dateFormat).add(14, 'days').format(dateFormat))
    }

    const onTapLeftWeek = () => {
        setCurrentWeekStartDate(moment(currentWeekStartDate, dateFormat).subtract(14, 'days').format(dateFormat))
        setCurrentWeekEndDate(moment(currentWeekStartDate, dateFormat).subtract(1, 'days').format(dateFormat))
    }

    const getMyEarningsCount = () => {
        let params = {
            startDate: parseInt(moment(currentWeekStartDate).format('x')),
            endDate: parseInt(moment(currentWeekEndDate).format('x'))
        };

        setBlocking(true);
        ApiServices.getMyEarningsCount(params)
            .then((response) => {
                setBlocking(false);
                setAdvancePaymentTotal(response?.data?.data?.advancePaymentDone)
                setAdvancePaymentPending(response?.data?.data?.advancePaymentPending)
                console.log("Response from my earnings", response.data.data);
            })
            .catch((e) => {
                console.log(e, "Failed to get conutries");
                toast.error(e?.response?.data?.msg);
                setBlocking(false);
                if (e.response.status == 401) {
                    localStorage.clear();
                    history.push("/");
                }
            })
    };

    const getMyEarningsList = () => {
        let params = {
            startDate: parseInt(moment(currentWeekStartDate).format('x')),
            endDate: parseInt(moment(currentWeekEndDate).format('x'))
        };

        setBlocking(true);
        ApiServices.getMyEarningsList(params)
            .then((response) => {
                setBlocking(false);
                setEarningsList(response?.data?.data)
                console.log("Response from my earnings", response.data.data);
            })
            .catch((e) => {
                console.log(e, "Failed to get conutries");
                toast.error(e.response.data.msg);
                setBlocking(false);
                if (e.response.status == 401) {
                    localStorage.clear();
                    history.push("/");
                }
            })
    };


    const onLimitChange = (event) => {
        setPageSize(event.target.value);
        setPage(1);
    };
    const previousPage = () => {
        if (Page > 1) {
            setPage(Page - 1);
        }
    };
    const nextPage = () => {
        if (Page < Math.ceil(totalCount / PageSize)) {
            setPage(Page + 1);
        }
    };

    return (
        <BlockUi blocking={Blocking}>
            <div>
                <main className="main-contant">
                    <div className="clearfix">
                        <h1 className="page-title float-left">Earnings</h1>
                    </div>
                    <div className="page-content">
                        <div className="h-100 d-flex justify-content-center">
                            <a onClick={onTapLeftWeek} className="navbar-brand text-center d-block mb-5 p-0">
                                <img src={LeftArrow} width={30} alt="site-logo" />
                            </a>
                            <h4 style={{ margin: '0 50px' }}>
                                {moment(currentWeekStartDate).format('DD/MM/YYYY')} - {moment(currentWeekEndDate).format('DD/MM/YYYY')}
                            </h4>
                            <a onClick={onTapRightWeek} className="navbar-brand text-center d-block mb-5 p-0">
                                <img src={RightArrow} width={30} alt="site-logo" />
                            </a>

                        </div>
                        <div className="navbar-brand text-center d-block mb-3" >
                            {/* <div className="h-100 d-flex justify-content-between" style={{ margin: '0 100px' }}> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-6 earningButtons">
                                        <button className="drbtn btn  btn-outline-primary">
                                            Advance Payment {'\n'} Total : {'£'}{advancePaymentTotal}
                                        </button>

                                    </div>
                                    <div className="col-sm-6">
                                        <button className="drbtn btn  btn-outline-primary">
                                            Advance Payment {'\n'} Pending : {'£'}{advancePaymentPending}
                                        </button>

                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                        </div>

                        <div className="card-wrap">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr key="head">
                                            <th>Barber ID</th>
                                            <th>Barber Name</th>
                                            <th>Advance Payment Total</th>
                                            <th>Pending Advance Payment Total</th>
                                            <th>Bank Account Name</th>
                                            <th>Bank Sort Code</th>
                                            <th>Bank Account Number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {earningsList !== undefined ? (
                                            earningsList.map((earningObj, index) => {
                                                return (
                                                    <tr key={index.toString()}>
                                                        <td key="1"> {earningObj?.providerIdCode ? earningObj?.providerIdCode : '-'} </td>
                                                        <td key="2"> {earningObj?.name ? earningObj?.name + "(" + earningObj?.bussinessName + ")" : '-'} </td>
                                                        <td key="3"> {'£'}{earningObj?.advancePaymentDone} </td>
                                                        <td key="4"> {'£'}{earningObj?.advancePaymentPending} </td>
                                                        <td key="5"> {earningObj?.bankAccountHolderName ? earningObj?.bankAccountHolderName : '-'} </td>
                                                        <td key="6"> {earningObj?.bankSortCode ? earningObj?.bankSortCode : '-'}</td>
                                                        <td key="7"> {earningObj?.bankAccountNumber ? earningObj?.bankAccountNumber : '-'}</td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <div className="text-center">
                                                <p>No Country Found</p>
                                            </div>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            {/* <div className="paginations">
                                <p>Items per page:</p>
                                <select onChange={(e) => onLimitChange(e)} name="limit">
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={30}>30</option>
                                    <option value={40}>40</option>
                                    <option value={50}>50</option>
                                </select>
                                <p>
                                    <span>{firstText}</span>-<span>{secondText}</span> of{" "}
                                    <span>{totalCount}</span>
                                    <i className="fa fa-angle-left" onClick={previousPage} />
                                    <i className="fa fa-angle-right" onClick={nextPage} />
                                </p>
                            </div> */}
                        </div>
                    </div>
                </main>
            </div>
        </BlockUi>
    );
};

export default Earnings;
