import React, { useEffect, useState } from "react";
import { ApiServices } from "../../../services/ApiServices";
import { Link, useHistory } from "react-router-dom";
import BlockUi from "react-block-ui";
import moment from "moment";

const DiscountCodes = () => {
  let history = useHistory();
  const [Blocking, setBlocking] = useState(false);
  const [DiscountCodesList, setDiscountCodesList] = useState([]);
  const [Page, setPage] = useState(1);
  const [PageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [firstText, setFirstText] = useState(0);
  const [secondText, setSecondText] = useState(0);
  const [SearchParam, setSearchParam] = useState("");
  
  useEffect(() => {
    getDiscountCodesList();
  }, [PageSize, Page]);

  useEffect(() => {
    if (DiscountCodesList.length === 0) {
      setSecondText(0);
      setFirstText(0);
    } else {
      setFirstText(Page * PageSize - PageSize + 1);
      if (DiscountCodesList.length !== PageSize) {
        setSecondText(Math.abs(Page * PageSize - PageSize + DiscountCodesList.length));
      } else {
        setSecondText(Page * PageSize);
      }
    }
  }, [DiscountCodesList]);

  const getDiscountCodesList = () => {
    let params = {
      page: Page,
      PageSize: PageSize,
    };
    if (SearchParam !== "" || SearchParam !== undefined) {
      params.search = SearchParam;
    }
    setBlocking(true);
    ApiServices.getDiscountCodes(params)
      .then((response) => {
        setDiscountCodesList(response.data.data);
        setTotalCount(response.data.count);
        setBlocking(false);
      })
      .catch((e) => {
        console.log(e, "Failed to get conutries");
      });
  };
  const onLimitChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };
  const previousPage = () => {
    if (Page > 1) {
      setPage(Page - 1);
    }
  };
  const nextPage = () => {
    if (Page < Math.ceil(totalCount / PageSize)) {
      setPage(Page + 1);
    }
  };
  const handleEdit = (EditOn) => {
    history.push({
      pathname: "/add-discount-codes",
      state: { Id: EditOn, setEdit: true },
    });
  };
  const handleSarch = () => {
    setPage(1)
    getDiscountCodesList();
  };
  return (
    <div>
      <BlockUi blocking={Blocking}>
        <main className="main-contant">
          <div className="clearfix">
            <h1 className="page-title float-left">Discount Codes</h1>
          </div>
          <div className="page-content">
            <div className="filter-wrap mt-4">
              <ul className="list-inline clearfix">
                <li className="list-inline-item">
                  <ul className="llist-inline pl-0 clearfix m-0 secrch-wrap">
                    <li className="list-inline-item mr-3 secrch-input" key="Search">
                      <input
                        type="text"
                        onChange={(e) => setSearchParam(e.target.value)}
                        className="form-control"
                        placeholder="Search here..."
                      />
                    </li>
                    <li className="list-inline-item mr-0 secrch-btn" key="serch_btn">
                      <button
                        className="drbtn btn  btn-outline-primary"
                        onClick={handleSarch}
                      >
                        Search
                      </button>
                    </li>
                  </ul>
                </li>
                <li className="list-inline-item float-right" key="add">
                  <Link
                    to={{
                      pathname: "/add-discount-codes",
                      state: { Id: undefined, setEdit: false },
                    }}
                  >
                    <button className="drbtn btn  btn-outline-primary">
                      Add Discount Code
                    </button>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="card-wrap">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr key="head">
                      <th>Promo Codes</th>
                      <th>Code Type</th>
                      <th>Barber name and code</th>
                      <th>credits to add</th>
                      <th>Valid From</th>
                      <th>Expiry Date</th>
                      <th>User Count Limit</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {DiscountCodesList !== undefined ? (
                      DiscountCodesList.map((discountCode, index) => {
                        return (
                          <tr key={index.toString()}>
                            <td> {discountCode.promoCode} </td>
                            <td> {discountCode.codeType} </td>
                            <td> {discountCode.bussinessName} </td>
                            <td> {discountCode.credit} </td>
                            <td> {moment(Number(discountCode.validFrom)).format("DD/MM/YYYY")}</td>
                            <td> {moment(Number(discountCode.expiryDate)).format("DD/MM/YYYY")}</td>
                            <td> {discountCode.minimumCredit} </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-outline-success"
                                onClick={() => handleEdit(discountCode.id)}
                              >
                                Edit
                              </button>
                            </td>
                            {/* <td> {discountCode.status==1?"Enable":"Disable"} </td> */}
                          </tr>
                        );
                      })
                    ) : (
                        <div className="text-center">
                          <p>No Discount Code Found</p>
                        </div>
                      )}
                  </tbody>
                </table>
              </div>
              <div className="paginations">
                <p>Items per page:</p>
                <select onChange={onLimitChange} name="limit">
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                  <option value={50}>50</option>
                </select>
                <p>
                  <span>{firstText}</span>-<span>{secondText}</span> of{" "}
                  <span>{totalCount}</span>
                  <i className="fa fa-angle-left" onClick={previousPage} />
                  <i className="fa fa-angle-right" onClick={nextPage} />
                </p>
              </div>
            </div>
          </div>
        </main>
      </BlockUi>
    </div>
  );
};

export default DiscountCodes;
