import React, { useEffect, useState } from "react";
import { ApiServices } from "../../../services/ApiServices";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";

const AddCountries = () => {
  let history = useHistory();
  const [Price, setPrice] = useState("");
  const [Error, setError] = useState(false);
  const [Purchase, setPurchase] = useState("");
  const [Country, setCountry] = useState("");
  const [status, setStatus] = useState(1);
  const [TrialCredit, setTrialCredit] = useState("1000000");
  const [isEdit, setIsEdit] = useState(false);
  // const [update, setUpdate] = useState(false);
  const [LocalCurrency, setLocalCurrency] = useState("");
  const [CurrencSign, setCurrencSign] = useState("");
  const [TopupCurrency, setTopupCurrency] = useState("");
  const [CountryDropList, setCountryDropList] = useState([]);
  const [CurrencSignDropList, setCurrencSignDropList] = useState([]);
  const [TopupCurrencyDropList, setTopupCurrencyDropList] = useState([]);
  const [SortCodeLabel, setSortCodeLabel] = useState("");
  const [AccountNumber, setAccountNumber] = useState("");
  const [bookingCharges, setBookingCharges] = useState("");
  const [transactionFee, setTransactionFee] = useState("");
  const [percentage, setPercentage] = useState("");

  const [CountryCode, setCountryCode] = useState([]);

  useEffect(() => {
    if (window.location.search.substring(1)) {
      setIsEdit(true)
      getCountryDetail(window.location.search.substring(1))
    }
    ApiServices.getCountryDropList()
      .then((response) => {
        setCountryDropList(response.data.data.country);
        setTopupCurrencyDropList(response.data.data.currencyCode);
        setCurrencSignDropList(response.data.data.currencySign);
      })
      .catch((e) => {
        console.log(e, "Failed to get conutries");
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const values = {
      pricePerCredit: Price,
      minimumCredit: Purchase,
      countryName: Country,
      currencyTopup: TopupCurrency,
      localCurrency: LocalCurrency,
      currencySign: CurrencSign,
      creditsOffered: TrialCredit,
      status: status,
      sortCodeLable: Country === "United Kingdom" ? "Sort Code" : "Swift Code",
      accountNumberLable: Country === "United Kingdom" ? "Account Number" : "IBAN Number",
      mobCode: CountryCode,
      bookingCharges: bookingCharges,
      transactionFee: transactionFee,
      percentage: percentage
    };

    if (
      values.countryName === "" ||
      values.minimumCredit === "" ||
      values.pricePerCredit === "" ||
      values.currencyTopup === "" ||
      values.currencySign === "" ||
      values.localCurrency === "" || values.bookingCharges === "" || values.transactionFee === "" || values.percentage === ""
    ) {
      toast.error("Fields Are Required");
      setError(true)
    } else {
      setError(false);
      if (isEdit) {
        values.countryId = window.location.search.substring(1)
        EditCountry(values)
      } else {
        AddCountry(values)
      }
    }
  };

  const AddCountry = (values) => {
    ApiServices.AddCountries(values)
      .then((response) => {
        let res = response.data;
        if (res.status === 200) {

          toast.success(res.msg);
          history.push({
            pathname: "/countries",
          });
        } else {
          toast.error(res.msg);
        }
      })
      .catch((e) => {
        console.log(e)
      });
  };

  const EditCountry = (values) => {
    ApiServices.EditCountries(values)
      .then((response) => {
        let res = response.data;
        if (res.status === 200) {

          toast.success(res.msg);
          history.push({
            pathname: "/countries",
          });
        } else {
          toast.error(res.msg);
        }
      })
      .catch((e) => {
        console.log(e)
      });
  };

  const getCountryDetail = (values) => {
    ApiServices.getCountryDetails(values)
      .then((response) => {
        let res = response?.data?.data;
        if (response?.data?.status === 200) {
          setPrice(res?.pricePerCredit)
          setPurchase(res?.minimumCredit)
          setCountry(res?.country)
          setTopupCurrency(res?.currencyTopup)
          setLocalCurrency(res?.currencyLocal)
          setCurrencSign(res?.currencySign)
          setTrialCredit(res?.creditsOffered)
          setStatus(parseInt(res?.status))
          setSortCodeLabel(res?.sortCodeLable)
          setAccountNumber(res?.accountNumberLable)
          setCountryCode(res?.mobCode)
          setBookingCharges(res?.bookingCharges)
          setTransactionFee(res?.transactionFee)
          setPercentage(res?.percentage)

        } else {
          toast.error(response?.data?.msg);
        }
      })
      .catch((e) => {
        console.log(e)
      });
  };

  const funcSetCountryCode = (name) => {
    let final = CountryDropList.find((value, index) => { return value.name === name });
    if (final != undefined) {
      setCountryCode(final.dial_code);
      setCountry(final.name);
    }
  };

  return (
    <div>
      <main className="main-contant">
        <div className="clearfix">
          <h1 className="page-title float-left">{isEdit ? 'Edit' : 'Add'} Country</h1>
        </div>
        <div className="page-content">
          <div className="card-wrap">
            <form name="loginForm" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor>Price per Credit</label>
                    <input
                      type="number"
                      placeholder="0"
                      step="0.1"
                      onChange={(e) => setPrice(e.target.value)}
                      className={Error && Price === '' ? "form-control Error" : "form-control"}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor>Minimum Credit Purchase</label>
                    <input
                      type="number"
                      placeholder="0"
                      step="0.1"
                      onChange={(e) => setPurchase(e.target.value)}
                      className={Error && Purchase === '' ? "form-control Error" : "form-control"}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor>Trial Credit Offered</label>
                    <input
                      type="number"
                      placeholder="10,00,000"
                      disabled
                      // onChange={(e) => setTrialCredit(e.target.value)}
                      className={Error && TrialCredit === '' ? "form-control Error" : "form-control"}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor>Country</label>
                    <select
                      value={Country}
                      className={Error && Country === '' ? "form-control Error" : "form-control"}
                      onChange={(e) => funcSetCountryCode(e.target.value)}
                    >
                      <option value="">Select</option>
                      {CountryDropList !== undefined ? (
                        CountryDropList.map((countrieName, index) => {
                          return <option value={countrieName.name}>{countrieName.name}</option>;
                        })
                      ) : (
                        <option value="no value">no value</option>
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor>Country Code</label>
                    <input
                      type="text"
                      value={CountryCode}
                      disabled={true}
                      onChange={(e) => setCountryCode(e.target.value)}
                      className={Error && CountryCode === '' ? "form-control Error" : "form-control"}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-4">
                    <label htmlFor>Select Currency for top up</label>
                    <select
                      value={TopupCurrency}
                      className={Error && TopupCurrency === '' ? "form-control Error" : "form-control"}
                      onChange={(e) => setTopupCurrency(e.target.value)}
                    >
                      <option value="">Select</option>
                      {TopupCurrencyDropList !== undefined ? (
                        TopupCurrencyDropList.map((countrieName, index) => {
                          return <option value={countrieName}>{countrieName}</option>;
                        })
                      ) : (
                        <option value="no value">no value</option>
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-4">
                    <label htmlFor>Local Currency for service price</label>
                    <select
                      value={LocalCurrency}
                      className={Error && LocalCurrency === '' ? "form-control Error" : "form-control"}
                      onChange={(e) => setLocalCurrency(e.target.value)}
                    >
                      <option value="">
                        Select
                      </option>
                      {TopupCurrencyDropList !== undefined ? (
                        TopupCurrencyDropList.map((countrieName, index) => {
                          return <option value={countrieName}>{countrieName}</option>;
                        })
                      ) : (
                        <option value="no value">no value</option>
                      )}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-4">
                    <label htmlFor>Currency Sign</label>
                    <select
                      value={CurrencSign}
                      className={Error && CurrencSign === '' ? "form-control Error" : "form-control"}
                      onChange={(e) => setCurrencSign(e.target.value)}
                    >
                      <option value="">Select</option>
                      {CurrencSignDropList !== undefined ? (
                        CurrencSignDropList.map((countrieName, index) => {
                          return <option value={countrieName}>{countrieName}</option>;
                        })
                      ) : (
                        <option value="no value">no value</option>
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor>Account Number Label</label>
                    <input
                      type="text"
                      placeholder={Country === "" ? "" : Country === "United Kingdom" ? "Account Number Label" : "IBAN Number Label"}
                      disabled={true}
                      value={AccountNumber}
                      onChange={(e) => setAccountNumber(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor>Sort Code Label</label>
                    <input
                      type="text"
                      disabled={true}
                      placeholder={Country === "" ? "" : Country === "United Kingdom" ? "Sort Code Label" : "Swift Code Label"}
                      value={SortCodeLabel}
                      onChange={(e) => setSortCodeLabel(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor>Booking Fee</label>
                    <input
                      type="text"
                      placeholder="Booking Fee"
                      value={bookingCharges}
                      onChange={(e) => setBookingCharges(e.target.value)}
                      className={Error && bookingCharges === '' ? "form-control Error" : "form-control"}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor>Transaction Fee</label>
                    <input
                      type="text"
                      placeholder="Transaction Fee"
                      value={transactionFee}
                      onChange={(e) => setTransactionFee(e.target.value)}
                      className={Error && transactionFee === '' ? "form-control Error" : "form-control"}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor>Percentage(%)</label>
                    <input
                      type="text"
                      placeholder="Percentage(%)"
                      value={percentage}
                      onChange={(e) => setPercentage(e.target.value)}
                      className={Error && percentage === '' ? "form-control Error" : "form-control"}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <button
                    type="submit"
                    className="drbtn btn btn-outline-primary float-right">
                    Save
                  </button>
                  <Link to="/countries">
                    <button
                      type="button"
                      className="drbtn btn btn-outline-primary float-right mr-2">
                      Cancel
                    </button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AddCountries;

