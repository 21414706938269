import React, { useState, useEffect } from "react";
import { ApiServices } from "../../../services/ApiServices";
import { toast } from "react-toastify";
import { Link, useLocation, useHistory } from "react-router-dom";
import BlockUi from "react-block-ui";
import moment from "moment";
import Select from 'react-select'
const AddDiscountCode = () => {
  let location = useLocation();
  let history = useHistory();
  const [Blocking, setBlocking] = useState(false);
  const [PromoCode, setPromoCode] = useState("");
  const [Credits, setCredits] = useState("");
  const [UserLimit, setUserLimit] = useState("");
  const [EditId, setEditId] = useState(location.state.Id);
  const [IsEdit, setIsEdit] = useState(location.state.setEdit);
  const [ValidDate, setValidDate] = useState("");
  const [DateChanged, setDateChanged] = useState(false);
  const [DateChangedExp, setDateChangedExp] = useState(false);
  const [ExpiryDate, setExpiryDate] = useState("");
  const [ProviderId, setProviderId] = useState("");
  const [CodeType, setCodeType] = useState("CUSTOMER");
  const [Options, setOptions] = useState([]);
  const [PromoDetails, setPromoDetails] = useState([]);
  const [Error, setError] = useState(false);

  useEffect(() => {
    ApiServices.getBarbourName()
        .then((response) => {
          const Temp = [];
          response.data.data.map((key, index) => {
            Temp.push({
              value: key.providerId,
              label: key.bussinessName
            })
          })
          setOptions(Temp)
        })
        .catch((e) => {
          console.log(e, "Failed to get Barbour Name");
        });

    if (IsEdit) {
      setBlocking(true);
      ApiServices.getPromoCodeByID(EditId)
          .then((response) => {
            setPromoDetails(response.data.data);
            setPromoCode(response.data.data[0].promoCode);
            setCodeType(response.data.data[0].codeType);
            setCredits(response.data.data[0].credit);
            setUserLimit(response.data.data[0].countLimit);
            setValidDate(response.data.data[0].validFrom);
            setExpiryDate(response.data.data[0].expiryDate);
            setProviderId(response.data.data[0].providerId);
            setBlocking(false);
          })
          .catch((e) => {
            console.log(e, "Failed to get Barbour Name");
          });
    } else {
      // console.log("edit OFF", EditId);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const values = {
      promoCode: PromoCode,
      providerId: ProviderId,
      codeType: CodeType,
      credit: Credits,
      validFrom: ValidDate,
      expiryDate: ExpiryDate,
      countLimit: UserLimit,
    };
    if (
        values.promoCode === "" ||
        values.codeType === "" ||
        values.validFrom === "" ||
        values.expiryDate === ""
    ) {
      toast.error("Fields Are Required");
      setError(true);
    } else if (values.codeType === "CUSTOMER" && values.credit === "") {
      toast.error("Fields Are Required");
      setError(true);
    } else if (values.providerId === "" && values.codeType !== "CUSTOMER") {
      toast.error("Fields Are Required");
      setError(true);
    } else {
      setError(false);
      AddDiscountCode(values);
    }
  };
  const AddDiscountCode = (values) => {
    setBlocking(true);
    ApiServices.AddDiscountCode(values)
        .then((response) => {
          let res = response.data;
          if (res.status === 200) {
            toast.success(res.msg);
            // ResetForm();
            setBlocking(false);
            history.push({
              pathname: "/discount-codes",
            });
          } else {
            toast.error(res.msg);
          }
        })
        .catch((e) => {
        });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const values = {
      id: EditId,
      promoCode: PromoCode,
      providerId: PromoCode === "CUSTOMER" ? "" : ProviderId,
      codeType: CodeType,
      credit: ProviderId === "" && CodeType === "CUSTOMER" ? Credits : "",
      validFrom: ValidDate,
      expiryDate: ExpiryDate,
      countLimit: UserLimit,
    };
    if (
        // values.promoCode === "" ||
    // values.codeType === "" ||
    // values.validFrom === "" ||
        values.expiryDate === ""
    ) {
      toast.error("Fields Are Required");
      setError(true);
    }

    else {
      setError(false);
      UpdateDiscountCode(values);
    }
  };

  const UpdateDiscountCode = (values) => {
    setBlocking(true);
    ApiServices.PromoUpdate(values)
        .then((response) => {
          let res = response.data;
          if (res.status === 200) {
            toast.success(res.msg);
            setBlocking(false);
            history.push({
              pathname: "/discount-codes",
            });
          } else {
            toast.error(res.msg);
          }
        })
        .catch((e) => {
          console.log(e);
        });
  };
  const handleSelect = (e) => {
    // sete()
    setProviderId(e.value)
  }
  return (
      <div>
        <BlockUi blocking={Blocking}>
          <main className="main-contant">
            <div className="clearfix">
              <h1 className="page-title float-left">
                {IsEdit ? "Edit Discount Code" : "Add Discount Code"}
              </h1>
            </div>
            <div className="page-content">
              <div className="card-wrap">
                <form
                    name="loginForm"
                    onSubmit={IsEdit ? handleUpdate : handleSubmit}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor>Promo Code</label>
                        <input
                            type="text"
                            disabled={IsEdit ? true : false}
                            value={PromoCode}
                            onChange={(e) => setPromoCode(e.target.value)}
                            className={
                              Error && PromoCode === ""
                                  ? "form-control Error"
                                  : "form-control"
                            }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor>Code Type</label>
                        <select
                            className="form-control"
                            value={CodeType}
                            disabled={IsEdit ? true : false}
                            onChange={(e) => setCodeType(e.target.value)}
                        >
                          <option value="CUSTOMER">Customer Specific</option>
                          <option value="BARBER">Barber Specific</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      {CodeType === "CUSTOMER" ? (
                          <div className="form-group">
                            <label htmlFor>Credits To Add</label>
                            <input
                                type="number"
                                disabled={IsEdit ? true : false}
                                value={Credits}
                                onChange={(e) => setCredits(e.target.value)}
                                className={
                                  Error && Credits === ""
                                      ? "form-control Error"
                                      : "form-control"
                                }
                            />
                          </div>
                      ) : (
                          <div className="form-group">
                            {IsEdit ? "" : <div>
                              <label htmlFor>Business Name</label>
                              <Select options={Options} placeholder isDisabled={IsEdit ? true : false} onChange={(e) => handleSelect(e)} />
                            </div>}
                          </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor>User Count Limit<br></br>
                        </label>
                        <input
                            disabled={IsEdit ? true : false}
                            type="number"
                            value={UserLimit}
                            onChange={(e) => setUserLimit(e.target.value)}
                            className={
                              Error && UserLimit === ""
                                  ? "form-control Error"
                                  : "form-control"
                            }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor>Valid Date</label>
                        <input
                            disabled={IsEdit ? true : false}
                            type="date"
                            min={moment(new Date()).format("YYYY-MM-DD")}
                            value={
                              IsEdit && !DateChanged
                                  ? moment(Number(ValidDate)).format("YYYY-MM-DD")
                                  : null
                            }
                            onChange={(e) => {
                              setValidDate(new Date(e.target.value).getTime());
                              setDateChanged(true);
                            }}
                            className={
                              Error && ValidDate === ""
                                  ? "form-control Error"
                                  : "form-control"
                            }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor>Expiry Date</label>
                        <input
                            type="date"
                            min={moment(new Date()).format("YYYY-MM-DD")}
                            value={
                              IsEdit && !DateChangedExp
                                  ? moment(Number(ExpiryDate)).format("YYYY-MM-DD")
                                  : null
                            }
                            onChange={(e) => {
                              setExpiryDate(new Date(e.target.value).getTime());
                              setDateChangedExp(true);
                            }}
                            className={
                              Error && ExpiryDate === ""
                                  ? "form-control Error"
                                  : "form-control"
                            }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      {IsEdit ? (
                          <button
                              type="submit"
                              className="drbtn btn btn-outline-primary float-right"
                          >
                            Update
                          </button>
                      ) : (
                          <button
                              type="submit"
                              className="drbtn btn btn-outline-primary float-right"
                          >
                            Save
                          </button>
                      )}

                      <Link to="/discount-codes">
                        <button
                            type="button"
                            className="drbtn btn btn-outline-primary float-right mr-2"
                        >
                          Cancel
                        </button>
                      </Link>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </main>
        </BlockUi>
      </div>
  );
};

export default AddDiscountCode;
