import React, { useState } from "react";
import { ApiServices } from "../../../services/ApiServices";
import { toast } from "react-toastify";
import { isEmail } from "validator";
import { Link } from "react-router-dom";
import Logo from "../../../assets/images/logo.png";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    const values = { email: email };
    if (values.email.trim() === "" && !isEmail(values.email)) {
      toast.error("Enter Valid Credentials");
    } else {
      // FORGOT API SERVICE CALL
      ApiServices.forgotPassword(values)
        .then((response) => {
          let res = response.data;
          if (res.status === 200) {
            toast.success(res.msg);
            setEmail("");
          } else {
            toast.error(res.msg);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <div>
      <div className="container">
        <div className="user-login-wrap row p-3">
          <div className="card-wrap col-md-5 mx-auto add-question-wrap">
            <a className="navbar-brand text-center d-block mb-5 p-0">
              <img src={Logo} width={150} alt="site-logo" />
            </a>
            <form name="loginForm" onSubmit={handleSubmit}>
              <div className="form-group mb-4">
                <label htmlFor>Email</label>
                <input
                  type="email"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="drbtn btn btn-outline-primary"
                  style={{ width: "100%" }}
                >
                  Reset
                </button>
              </div>
            </form>
            <Link to="/" className="forgotpass">
              Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
